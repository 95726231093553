// @flow

import * as Immutable from 'immutable';
import * as _ from 'lodash';
import { autofill } from 'redux-form/immutable';

import type { State, Action, Dispatch } from '../../lib/types';
import { inicializaCampos } from './functions';

export const INICIALIZA_MODAL        = 'lista_documentos/nfse/INICIALIZA_MODAL',
             INICIALIZA_VALORES_NFSE = 'lista_documentos/nfse/INICIALIZA_VALORES_NFSE',
             REINICIA_VALORES_NFSE   = 'lista_documentos/nfse/REINICIA_VALORES_NFSE';

const DEFAULT_STATE_JS = {
  query: null, formato: 'txt', tipoDocumento: 'nfse', delimiter: ';', linhaPor: 1, salvarRelatorio: false, visibilidade: 'publica',
  dadosBasicos: {
    chave: false, numero: false, codigoVerificacao: false, emitidoEm: false, municipio: false,
    discriminacao: false, valor: false, tributacao: false, simplesOpcaoDesc: false,
  },
  transacoesConsyste: { createdAt: false, statusSefazDesc: false },
  prestador: { prestadorCpfCnpj: false, prestadorRazaoSocial: false, prestadorIm: false },
  tomador: { tomadorCpfCnpj: false, tomadorRazaoSocial: false, tomadorIm: false },
};

const DEFAULT_STATE = Immutable.fromJS(DEFAULT_STATE_JS);

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action) {
  switch (action.type) {
    case INICIALIZA_MODAL:
      return state.set('query', action.query);
    case INICIALIZA_VALORES_NFSE:
      return inicializaCampos(state, action.campos);
    case REINICIA_VALORES_NFSE:
      return DEFAULT_STATE.set('query', state.get('query'));
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['listaDocumentos', 'nfse'], DEFAULT_STATE);

// thunk actions

export function atualizaSelecaoTodos(campos : string[], valor : bool) {
  return async function(dispatch : Dispatch<*>) {
    campos.forEach((campo : string) => {
      const grupoCheckbox = _.cloneDeep(DEFAULT_STATE_JS[campo]);

      Object.keys(grupoCheckbox).forEach(k => grupoCheckbox[k] = valor);

      return dispatch(autofill('listaDocumentosNfse', campo, Immutable.fromJS(grupoCheckbox)));
    });
  };
}
