// @flow
'use strict';

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { State, Action, Dispatch } from 'lib/types';

export const ALTERA_URL                        = 'nfe/manifestacao/ALTERA_URL',
             ALTERA_MANIFESTACAO               = 'nfe/manifestacao/ALTERA_MANIFESTACAO',
             EXIBE_ALERTA                      = 'nfe/manifestacao/EXIBE_ALERTA',
             FECHA_ALERTA                      = 'nfe/manifestacao/FECHA_ALERTA',
             ABRE_MODAL                        = 'nfe/manifestacao/ABRE_MODAL',
             FECHA_MODAL                       = 'nfe/manifestacao/FECHA_MODAL',
             ABRE_MODAL_ATUALIZA_MANIFESTACAO  = 'nfe/manifestacao/ABRE_MODAL_ATUALIZA_MANIFESTACAO',
             FECHA_MODAL_ATUALIZA_MANIFESTACAO = 'nfe/manifestacao/FECHA_MODAL_ATUALIZA_MANIFESTACAO',
             SET_JUSTIFICATIVA                 = 'nfe/manifestacao/SET_JUSTIFICATIVA',
             HABILITA_BOTAO                    = 'nfe/manifestacao/HABILITA_BOTAO',
             DESABILITA_BOTAO                  = 'nfe/manifestacao/DESABILITA_BOTAO';

const DEFAULT_STATE = Immutable.fromJS({
  url: '',
  open: false,
  openModalAtualizaManifestacao: false,
  manifestacao: '',
  alertas: '',
  manifestacaoJustificativa: '',
  botaoDesabilitado: false,
});

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action) {
  switch (action.type) {
    case ALTERA_URL:
      return state.set('url', action.url);
    case ALTERA_MANIFESTACAO:
      return state.set('manifestacao', action.manifestacao);
    case EXIBE_ALERTA:
      return state.set('alertas', action.alertas);
    case FECHA_ALERTA:
      return state.set('alertas', '');
    case ABRE_MODAL:
      return state.set('open', true);
    case FECHA_MODAL:
      return state.set('open', false);
    case ABRE_MODAL_ATUALIZA_MANIFESTACAO:
      return state.set('openModalAtualizaManifestacao', true);
    case FECHA_MODAL_ATUALIZA_MANIFESTACAO:
      return state.set('openModalAtualizaManifestacao', false);
    case SET_JUSTIFICATIVA:
      return state.set('manifestacaoJustificativa', action.justificativa);
    case HABILITA_BOTAO:
      return state.set('botaoDesabilitado', false);
    case DESABILITA_BOTAO:
      return state.set('botaoDesabilitado', true);
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['nfe', 'manifestacao'], DEFAULT_STATE);
export const openModalSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('open'));
export const openModalAtualizaManifestacaoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('openModalAtualizaManifestacao'));
export const manifestacaoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('manifestacao'));
export const alertasSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('alertas'));
export const botaoDesabilitadoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('botaoDesabilitado'));

// action

function fecharModal() {
  return { type: FECHA_MODAL };
}

export function fechaModalAtualizaManifestacao() {
  return { type: FECHA_MODAL_ATUALIZA_MANIFESTACAO };
}

export function abreModalAtualizaManifestacao() {
  return { type: ABRE_MODAL_ATUALIZA_MANIFESTACAO };
}

export function alteraUrl(url : string) {
  return { type: ALTERA_URL, url };
}

export function alteraManifestacao(manifestacao : string) {
  return { type: ALTERA_MANIFESTACAO, manifestacao };
}

export function exibeAlertas(alertas : string) {
  return { type: EXIBE_ALERTA, alertas };
}

export function fechaAlertas() {
  return { type: FECHA_ALERTA };
}

export function abreModal() {
  return { type: ABRE_MODAL };
}

export function setJustificativa(justificativa : string) {
  return { type: SET_JUSTIFICATIVA, justificativa };
}

export function habilitaBotao() {
  return { type: HABILITA_BOTAO };
}

export function desabilitaBotao() {
  return { type: DESABILITA_BOTAO };
}

// thunk actions

export function fechaModal() {
  return function(dispatch : Dispatch<*>) {
    dispatch(fecharModal());
    dispatch(alteraManifestacao(''));
    dispatch(exibeAlertas(''));
    dispatch(setJustificativa(''));
    dispatch(habilitaBotao());
  };
}

export function abrirModalAtualizaManifestacao() {
  return function(dispatch : Dispatch<*>) {
    dispatch(fechaModal());
    dispatch(abreModalAtualizaManifestacao());
  }
}

export function fecharModalAtualizaManifestacao() {
  return function(dispatch : Dispatch<*>) {
    dispatch(fechaModalAtualizaManifestacao());
    dispatch(alteraManifestacao(''));
    dispatch(exibeAlertas(''));
    dispatch(setJustificativa(''));
  }
}
