// @flow
'use strict';

import React from 'react';
import Select from 'react-select';
import { Button, Form, Modal, ControlLabel } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { useEffectOnce } from 'lib/viewUtils';
import { OPCOES_MANIFESTACAO } from '../constants';
import {
  manifestacaoSelector,
  openModalAtualizaManifestacaoSelector,
  alteraUrl,
  alteraManifestacao,
  fecharModalAtualizaManifestacao, alertasSelector,
} from 'reducers/nfe/manifestacao';

import Alertas from '../alertas';
import { InputField } from '../../../common/input';
import AlertWithIcon from '../../../common/alert_with_icon';

type ModalProps = {
  handleSubmit : () => void,
  url : string,
}

export default function ModalAtualizaManifestacao({ handleSubmit, url } : ModalProps) {
  const openModalAtualizaManifestacao = useSelector(openModalAtualizaManifestacaoSelector),
        manifestacao                  = useSelector(manifestacaoSelector),
        alertas                       = useSelector(alertasSelector);

  const dispatch            = useDispatch(),
        fecharModal         = React.useCallback(() => dispatch(fecharModalAtualizaManifestacao()), [dispatch]),
        alterarManifestacao = React.useCallback((s : any) => dispatch(alteraManifestacao(s?.value)), [dispatch]),
        sendForm    = React.useCallback(() => {
          if (window.confirm('Tem certeza que deseja alterar o valor do Manifesto já realizado? Essa operação não enviará manifesto à SEFAZ, apenas alterará o valor contido no campo de manifesto SEFAZ do Portal Consyste.'))
            handleSubmit();
        }, [handleSubmit]);

  useEffectOnce(() => {
    dispatch(alteraUrl(url));
  });

  return (
      <Modal show={ openModalAtualizaManifestacao } onHide={ fecharModal } dialogClassName="modal-lg">
        <Form onSubmit={ sendForm }>
          <Modal.Header closeButton>
            <Modal.Title>Informar manifesto anterior</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { alertas && <Alertas alertas={ alertas }/> }

            <p>Informe o valor que deseja que conste no Manifesto SEFAZ.</p>
            <AlertWithIcon bsStyle="info" icon="info-circle">
              <p>Essa opção não envia manifesto à SEFAZ, apenas informa o manifesto que já foi feito anteriormente.
                Recomendado para quando o manifesto foi realizado fora do Portal Consyste.</p>
            </AlertWithIcon>

            <Select value={ manifestacao } options={ OPCOES_MANIFESTACAO } placeholder="Opções" onChange={ alterarManifestacao }
                    menuStyle={{ textAlign: 'left' }} style={{ textAlign: 'left' }}/>

            { manifestacao === 'operacao_nao_realizada' &&
              <>
                <ControlLabel>
                  <abbr title="obrigatório">* </abbr>
                  Manifestação Justificativa
                </ControlLabel>
                <InputField name="manifestacaoJustificativa"/>
              </>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="default" onClick={ fecharModal }>Cancelar</Button>
            <Button type="button" bsStyle="primary" onClick={ sendForm }>Salvar Manifestação</Button>
          </Modal.Footer>
        </Form>
      </Modal>
  );
}
