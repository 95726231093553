'use strict';

// @flow

import * as React from 'react';
import { OverlayTrigger, Popover, Label } from 'react-bootstrap';

import Icone from 'components/common/icone';

import type { LabelSituacaoProps } from './tipos';

let lastId = 0;

export default function LabelSituacao({ label, tipo, classe, texto, justificativa, children } : LabelSituacaoProps) {
  if (!children && texto) {
    const textoChildren = justificativa ? `${texto}<br/><strong>Justificativa: </strong>${justificativa}` : texto;

    children = <span dangerouslySetInnerHTML={{ __html: textoChildren }}/>;
  }

  const popover = <Popover title={label} id={`lb-situacao-${++lastId}`}>{ children }</Popover>;

  return (
      <OverlayTrigger placement="left" overlay={popover}>
        <span className="situacao-label">
          <Label className={ classe } bsStyle={ tipo }><strong>{ label }</strong></Label> { ' ' }
          <Icone nome="info-circle" estilo="fas"/>
        </span>
      </OverlayTrigger>
  );
}
