// @flow
'use strict';

import * as _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';

import * as manifestacao from 'reducers/nfe/manifestacao';
import request from 'lib/request';
import { formToQuery } from 'lib/viewUtils';
import { alert } from 'reducers/alerts';

import ModalAtualizaManifestacao from './modal_atualiza_manifestacao';

export default compose(
  connect<*, *, *, *, *, *>(createStructuredSelector({
    initialValues: manifestacao.rootSelector,
  })),
  reduxForm({
    form: 'atualizaManifestacaoNfe', enableReinitialize: true,
    onSubmit(form, dispatch) {
      request.patch(form.get('url'))
        .accept('json')
        .query(formToQuery(_.omit(form.toJS(), 'url')))
        .then(() => {
          dispatch(manifestacao.fecharModalAtualizaManifestacao());
          dispatch(alert('info', 'Informação salva com sucesso.'));
        })
        .catch(e => {
          if (e && e.response && e.response.body) {
            if (e.response.body.alertas)
              dispatch(manifestacao.exibeAlertas(e.response.body.alertas.join('\n\n')));
            if (e.response.body.justificativa)
              dispatch(manifestacao.setJustificativa(e.response.body.justificativa));
          }
        });
    },
  }),
)(ModalAtualizaManifestacao);