'use strict';

// @flow

import * as React from 'react';
import { Panel } from 'react-bootstrap';

import type { IDocumento } from 'lib/api';
import { formatHelper as fmt } from '../../lib/viewUtils';

import LabelSituacao from './label_situacao';
import ComTooltip from '../common/com_tooltip';
import ManifestaNfe from '../documento/manifestacao_nfe/manifesta_nfe';

type ManifestacaoProps = {
  documento : ?IDocumento
};

/**
 * Painel de informações de manifestação.
 */
export default function Manifestacao({ documento } : ManifestacaoProps) {
  if (!documento || !documento.manifestacao)
    return null;

  const manif = documento.manifestacao;

  // Bloco responável por mostrar a ultima consulta
  const ultimaConsulta = manif.ultimaManifestacao ?
                           <p>Último manifesto:{ '  ' } { fmt.formatDate(manif.ultimaManifestacao) }</p> :
                           'Sem manifestação.';

  return (
      <Panel className="situacoes">
        <Panel.Heading>Manifesto</Panel.Heading>
        <Panel.Body>
          <dl>
            <dt>
              <ComTooltip hint="Manifesto efetuado pelo destinatário através do Portal Consyste. Lembre-se que, para ter validade, o manifesto precisa ser autorizado." posicao="top">
                <span>Realizado na Consyste</span>
              </ComTooltip>
            </dt>
            <dd><LabelSituacao {...manif.consyste} /></dd>
            <dt>
              <ComTooltip hint="Indica se a SEFAZ autorizou o manifesto realizado pelo Consyste." posicao="top">
                <span>Retorno SEFAZ</span>
              </ComTooltip>
            </dt>
            <dd><LabelSituacao {...manif.retornoSefaz} /></dd>
            <dt>
              <ComTooltip hint="Último valor recebido da SEFAZ. Nota: A SEFAZ envia essa informação através dos eventos (para o emitente) e através da confirmação de recebimento do manifesto realizado na Consyste (para o destinatário)." posicao="top">
                <span>Recebido da SEFAZ</span>
              </ComTooltip>
            </dt>
            <dd><LabelSituacao {...manif.sefaz} /></dd>
          </dl>
          <div className="ultima-consulta r">
            {ultimaConsulta}
          </div>
          <ManifestaNfe url={manif.url}/>
        </Panel.Body>
      </Panel>
  );
}
