// @flow
'use strict';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';

import { openModalSelector, abreModal, openModalAtualizaManifestacaoSelector } from '../../../../reducers/nfe/manifestacao';

import ModalManifestaNfe from '../modal_manifesta_nfe';
import ModalAtualizaManifestacao from '../modal_atualiza_manifestacao';
import Icone from '../../../common/icone';

type ManifestaNfeProps = {
  url? : string,
}

/**
 * O botão da manifestação com modal.
 *
 * @param url se estiver vazia, o botão ficará desabilitado e uma mensagem de orientação será exibida em um popover.
 */
export default function ManifestaNfe({ url } : ManifestaNfeProps) {
  const open       = useSelector(openModalSelector),
        openModalAtualizaManifestacao = useSelector(openModalAtualizaManifestacaoSelector),
        dispatch   = useDispatch(),
        abrirModal = React.useCallback(() => dispatch(abreModal()), [dispatch]);

  if (url) {
    return (
        <>
          <Button className="btn btn-action btn-block" type="button" onClick={ abrirModal }>
            <Icone nome="gavel" estilo="fas" flip="horizontal"> Enviar Manifestação </Icone>
          </Button>

          { open && <ModalManifestaNfe url={ url.replace('manifestacao', 'manifestar') }/> }
          { openModalAtualizaManifestacao && <ModalAtualizaManifestacao url={ url.replace('manifestacao', 'atualizar_manifestacao') }/> }
        </>
    );
  }

  return (
      <Button disabled block className="btn-action">
        <Icone nome="gavel" estilo="fas" flip="horizontal"> Enviar Manifestação </Icone>
      </Button>
  );
}
